import { useCallback } from "react";

/**
 * Hook to get the dimensions of an image
 * @returns A function that takes a File and returns a Promise with the image dimensions
 */
export function useGetImageDimensions(): (file: File) => Promise<{ width: number; height: number }> {
  return useCallback(function getImageDimensions(file: File): Promise<{ width: number; height: number }> {
    return new Promise<{ width: number; height: number }>((resolve, reject) => {
      const img = new Image();
      const blobUrl = URL.createObjectURL(file);
      img.src = blobUrl;
      img.onload = () => {
        resolve({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
        URL.revokeObjectURL(blobUrl);
      };
      img.onerror = (error) => {
        reject(new Error("Failed to load image"));
        URL.revokeObjectURL(blobUrl);
      };
    });
  }, []);
}
