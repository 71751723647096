import { ChangeEvent, CSSProperties, DragEvent, useCallback, useRef, useState } from "react";
import UploadIcon from "@assets/icons/new/upload-2_50px.svg?react";
import { FaroButton } from "@components/common/faro-button";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import { collectFilesRecursivelyFromItems } from "@components/common/sphere-dropzone/collect-files";
import { SdbProject } from "@custom-types/project-types";
import { useOnSelectFiles } from "@hooks/data-management/use-on-select-any-files";
import { useUploadErrorToast, UploadErrorToastType } from "@hooks/data-management/use-upload-error-toast";
import { haveFileNamesValidLength } from "@hooks/upload-tasks/upload-tasks-utils";
import { useIsUserDragging } from "@hooks/use-is-user-dragging";
import { Box, Breakpoint, SvgIcon } from "@mui/material";
import { ALLOWED_EXTENSIONS_ALL } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { BrowserUtils } from "@stellar/web-core";
import { sphereColors } from "@styles/common-colors";
import { CONTENT_MAX_WIDTH, withEllipsisThreeLines } from "@styles/common-styles";
import { APITypes } from "@stellar/api-logic";

/** Defines the size of the icon in pixels */
const ICON_SIZE = "80px";
/** Allow dropping multiple folders. Could also be provided as property if needed. */
const shouldAllowMultiUpload = false;
/** Defines the max width of the displayed text lines in a similar way as the EmptyPage component. */
const TEXT_MAX_WIDTH: { [key in Breakpoint]: CSSProperties["maxWidth"] } = {
  xs: "90%",
  sm: "85%",
  md: "80%",
  lg: "70%",
  xl: "60%",
};

interface Props {
  project: SdbProject;
  /** A map of the externalIds of the uploaded ELS scans, as returned by getUploadedIdsMap. */
  uploadedIdsMap: { [key: APITypes.UUID]: boolean },
  /**
   * Flag if the dropzone is used as a standalone element on an empty page before anything is uploaded,
   * or if it's used as hidden element for the workflow view with the stepper and table.
   * This affects a large number of stylings.
   */
  isStandalone: boolean;
}

/**
 * Component for dropping files or folders to upload in the Staging Area. Currently only Blink folders are supported.
 * Based on the SphereDropzone component which couldn't be re-used due to the large number of special requirements,
 * in particular the styling of the child elements and the usage as hidden element in front of the workflow's main
 * components.
 */
export function DataManagementDropzone({
  project,
  uploadedIdsMap,
  isStandalone,
}: Props): JSX.Element {
  const uploadErrorToast = useUploadErrorToast();
  const onSelectFiles = useOnSelectFiles(project);

  const [fileInputEl, setFileInputEl] = useState<HTMLInputElement | null>(null);
  const [isFileExplorerOpen, setIsFileExplorerOpen] = useState(false);
  // Item count, used together with shouldAllowMultiUpload.
  const [itemsCount, setItemsCount] = useState<number>(0);
  // Flag if the whole browser window is receiving a dragging event.
  const isAppDragging = useIsUserDragging();
  // Flag if the dropzone is receiving a dragging event.
  const [isDragging, setIsDragging] = useState<boolean>(false);
  // Flag if we're inside the onSelectFiles method while getting the necessary data.
  // In that case, a spinner is shown instead of the dropzone's normal captions.
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Additional onDragLeave and onDragEnter events are fired whenever one drags over the child elements, causing
  // flickering. I tried multiple other implementation ideas than a counter, e.g. from
  // https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element
  // to fix this, but none worked. I don't really understand why the simple event.target.id === '...' approach used in
  // WebShare's .../1_source/public/js/source/entity/uploadcontroller.js doesn't work here.
  // On the plus side, the counter solution should work in any browser.
  // The same problem exists for the original SphereDropzone component, but it's less noticeable there.
  const dragCounter = useRef<number>(0);

  const allowedExtensions = ALLOWED_EXTENSIONS_ALL;
  const allowedExtensionsStr = allowedExtensions.map((extension) => `.${extension}`).join(", ");

  // ##### Event handlers ##### //

  const onDragEnter = useCallback(
    (event: DragEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.preventDefault();

      const items = event.dataTransfer.items.length;
      setItemsCount(items);

      // Sets the appropriate drag-and-drop effect and allowed actions based on specified conditions.
      // This logic is used to allow users to add file in Safari where the dataTransfer event may not
      // work as expected.
      if (
        !isFileExplorerOpen &&
        (BrowserUtils.isSafari() || items === 1 || shouldAllowMultiUpload)
      ) {
        event.dataTransfer.dropEffect = "copy";
        event.dataTransfer.effectAllowed = "all";
      } else {
        event.dataTransfer.dropEffect = "none";
        event.dataTransfer.effectAllowed = "none";
      }

      setIsDragging(true);
      dragCounter.current++;
    },
    [isFileExplorerOpen, dragCounter]
  );

  const onDragOver = useCallback(
    (event: DragEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.preventDefault();

      const items = event.dataTransfer.items.length;
      setItemsCount(items);

      // Sets the appropriate drag-and-drop effect and allowed actions based on specified conditions.
      // This logic is used to allow users to add file in Safari where the dataTransfer event may not
      // work as expected.
      if (
        !isFileExplorerOpen &&
        (BrowserUtils.isSafari() || items === 1 || shouldAllowMultiUpload)
      ) {
        event.dataTransfer.dropEffect = "copy";
        event.dataTransfer.effectAllowed = "all";
      } else {
        event.dataTransfer.dropEffect = "none";
        event.dataTransfer.effectAllowed = "none";
      }

      setIsDragging(true);
    },
    [isFileExplorerOpen]
  );

  const onDragLeave = useCallback(
    (event: DragEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.preventDefault();

      dragCounter.current = Math.max(dragCounter.current - 1, 0);
      if (0 < dragCounter.current) {
        return;
      }

      setIsDragging(false);
      setItemsCount(0);
    }, [dragCounter]
  );

  /** Should not be necessary, but can't hurt to also handle onDragEnd. */
  const onDragEnd = useCallback(
    (event: DragEvent<HTMLDivElement>): void => {
      event.stopPropagation();
      event.preventDefault();

      setIsDragging(false);
      setItemsCount(0);
      dragCounter.current = 0;
    }, [dragCounter]
  );

  /** Selects files from drag & drop. */
  const onDrop = useCallback(
    async(event: DragEvent<HTMLDivElement>): Promise<void> => {
      try {
        // Show loading spinner while collecting file info.
        setIsLoading(true);

        onDragEnd(event);
        if (!event.dataTransfer) {
          return;
        }

        // Validate file/folder names. If they're too long it can lead to an error or unexpected behaviour.
        const areFileNamesValid = haveFileNamesValidLength(event.dataTransfer.files);
        if (!areFileNamesValid) {
          uploadErrorToast(UploadErrorToastType.tooLongFilename);
          return;
        }

        if (0 <= event.dataTransfer.items?.length) {
          const files = await collectFilesRecursivelyFromItems(event.dataTransfer.items,
            /* shouldAllowFolderUpload */ true, /* shouldAllowFiles */ false, allowedExtensions);
          await onSelectFiles(files, uploadedIdsMap);
        } else {
          await onSelectFiles(event.dataTransfer.files, uploadedIdsMap);
        }
      } finally {
        setIsLoading(false);
      }
    }, [allowedExtensions, onDragEnd, onSelectFiles, uploadErrorToast, uploadedIdsMap]
  );

  /** Selects files from the file explorer. */
  async function selectFileFromDialog(event: ChangeEvent<HTMLInputElement>): Promise<void> {
    try {
      // Show loading spinner while collecting file info.
      setIsLoading(true);

      setIsFileExplorerOpen(false);

      const files = event.target.files;
      if (!files || !files.length) {
        uploadErrorToast(UploadErrorToastType.noFiles);
        return;
      }

      // When a folder was selected, we get the individual files instead of a FileSystemDirectoryHandle/Entry.
      // But we could still recover the folder structure using files[*].webkitRelativePath.
      // Validate file/folder names. If they're too long it can lead to an error or unexpected behaviour.
      const areFileNamesValid = haveFileNamesValidLength(files);
      if (!areFileNamesValid) {
        uploadErrorToast(UploadErrorToastType.tooLongFilename);
        return;
      }
      await onSelectFiles(files, uploadedIdsMap);
    } finally {
      setIsLoading(false);
    }
  }

  /** Opens the browser's file input dialog. */
  const openFileExplorer = useCallback(() => {
    if (!fileInputEl) {
      return;
    }
    // Allow to select folders only when openFileExplorer
    // needed to to this cause react does not allow to set these attributes in the DOM/HTML
    // ... is not assignable to type 'DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    fileInputEl.setAttribute("webkitdirectory", "true");
    fileInputEl.setAttribute("directory", "true");
    // https://stackoverflow.com/a/76926836/4555850
    // We need oncancel to make this work: canBeDropped={!isFileExplorerOpen}.
    fileInputEl.oncancel = () => setIsFileExplorerOpen(false);
    fileInputEl.click();
    setIsFileExplorerOpen(true);
  }, [fileInputEl]);

  // ##### JSX element ##### //

  let borderStyle: string | undefined = `2px dashed ${sphereColors.gray300}`;
  if (!shouldAllowMultiUpload && 1 < itemsCount) {
    borderStyle = `2px solid ${sphereColors.red500}`;
  } else if (isDragging || isLoading) {
    borderStyle = `2px solid ${sphereColors.blue500}`;
  } else if (isAppDragging) {
    borderStyle = `2px dashed ${sphereColors.blue400}`;
  } else if (!isStandalone) {
    borderStyle = undefined;
  }

  const maxWidth = isStandalone ? undefined : {
    xs: CONTENT_MAX_WIDTH.xs,
    sm: CONTENT_MAX_WIDTH.sm,
    md: CONTENT_MAX_WIDTH.md,
    lg: CONTENT_MAX_WIDTH.lg,
    xl: CONTENT_MAX_WIDTH.xl,
  };

  // For the embedded dropzone, use a slightly transparent white while dragging or loading.
  const backgroundColor = !isStandalone && (isAppDragging || isDragging || isLoading) ?
    "rgba(255, 255, 255, 0.8)" :
    undefined;

  return (
    <Box sx={{
        visibility: (isStandalone || isAppDragging || isDragging || isLoading) ? undefined : "hidden",
        opacity: (isStandalone || isAppDragging || isDragging || isLoading) ? 1 : 0,
        backgroundColor,
        width: "100%",
        // 250 px is the current gap of the scan table to the screen top.
        height: "calc(100vh - 250px)",
        minHeight: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: borderStyle,
        borderRadius: isStandalone ? "10px" : undefined,
        cursor: (isStandalone && !isLoading) ? "pointer" : "default",
        zIndex: 9999,
        "&:hover": {
          border: (isStandalone || isLoading) ? `2px solid ${sphereColors.blue500}` : undefined,
        },
        // Having tried multiple layouts, a fixed position looked best for the embedded variant in most cases.
        position: isStandalone ? undefined : "fixed",
        maxWidth,
      }}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDragEnd={onDragEnd}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
      onDrop={onDrop}
      onClick={isStandalone ? openFileExplorer : undefined}
      data-testid="sa-dropzone-container"
    >
      {/* Hidden input element for the file explorer. */}
      <input
        // We need to make sure that the next time the file input dialog is opened, it doesn't still have the file list
        // from the previous time. Setting the value attribute to the empty string is the easiest way according to
        // https://stackoverflow.com/a/54599692
        value=""
        ref={setFileInputEl}
        type="file"
        style={{ display: "none" }}
        accept={allowedExtensionsStr}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises -- Please review lint error
        onChange={selectFileFromDialog}
        multiple={shouldAllowMultiUpload}
        data-testid="sa-dropzone-input"
      />
      {/* Box needed for vertical centering. */}
      <Box sx={{
        width: "100%",
      }}>
        {/* Spinner while collecting file info */}
        {isLoading &&
          <Box data-testid="sa-dropzone-spinner" sx={{
              display: "flex",
              justifyContent: "center",
              // It looks better when there's a bit more space to the bottom than to the top.
              marginBottom: "20px",
          }}>
            <FaroButtonSpinner
              loadingTrackColor={isStandalone ? sphereColors.gray50 : sphereColors.gray200 }
              size={ICON_SIZE}
              marginLeft="0px"
            />
          </Box>
        }

        {/* Elements */}
        {!isLoading &&
          <Box data-testid="sa-dropzone-elements">
            {/* Icon */}
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "40px",
            }}>
              <SvgIcon
                inheritViewBox
                component={UploadIcon}
                sx={{
                  height: ICON_SIZE,
                  width: ICON_SIZE,
                }}
              />
            </Box>

            {/* Title */}
            <Box data-testid="sa-dropzone-title" sx={{
                fontSize: "32px",
                fontWeight: "600",
                color: sphereColors.gray800,
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
            }}>
              <Box sx={{
                  maxWidth: TEXT_MAX_WIDTH,
                  ...withEllipsisThreeLines,
                  lineHeight: "initial",
                  textAlign: "center",
              }}>
                Add Blink scans to your project
              </Box>
            </Box>

            {/* Subtitle */}
            <Box data-testid="sa-dropzone-subtitle" sx={{
                fontSize: "16px",
                color: sphereColors.gray600,
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
            }}>
              <Box sx={{
                  maxWidth: TEXT_MAX_WIDTH,
                  ...withEllipsisThreeLines,
                  lineHeight: "initial",
                  textAlign: "center",
              }}>
                Drag & drop the folder with raw Blink data (.gls) which contains a file called "index-v2".
              </Box>
            </Box>

            {/* Upload button */}
            { isStandalone &&
              <Box sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
                marginBottom: "20px",
              }}>
                <FaroButton onClick={() => {
                  // Do nothing, already handled by the main Box element of the component.
                }}>
                  <Box data-testid="sa-dropzone-data-button">
                    Upload Data
                  </Box>
                </FaroButton>
              </Box>
            }
          </Box>
        }
      </Box>
    </Box>
  );
}
