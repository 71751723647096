import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { hasUserValidRoleProjectLevel } from "@utils/access-control/project/project-access-control";
import { RequiredRoleProjectLevelName } from "@utils/access-control/project/project-access-control-types";
import { selectedProjectContextSelector } from "@store/projects/projects-selector";
import { SdbProject } from "@custom-types/project-types";

interface Props {
  /** The internal name used in the UI to determine certain permissions */
  roleName: RequiredRoleProjectLevelName;

  /** The project to get the user's permission level. */
  selectedProject?: SdbProject | null;
}

export type ReturnProps = {
  [key in RequiredRoleProjectLevelName]: boolean;
} & {
  /** Wrapper to check if user has a permission to do given action (by role name) or not */
  hasUserPermissionProjectLevel: ({
    roleName,
    selectedProject,
  }: Props) => boolean;
};

/** Checks whether a user has permission for an action on project level or not */
export function useHasUserValidRoleProjectLevel({
  selectedProject = null,
}: Pick<Props, "selectedProject"> = {}): ReturnProps {
  const currentUser = useAppSelector(currentUserSelector);
  const projectContext = useAppSelector(selectedProjectContextSelector);

  const canDeleteMemberFromProject = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canDeleteMemberFromProject,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canView2dImagesFeature = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canView2dImagesFeature,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canViewVideoModeFeature = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canViewVideoModeFeature,
    currentUser,
    selectedProject,
    projectContext,
  });
  const canViewFaceBlurringFeature = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canViewFaceBlurringFeature,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canInviteUsersToPrivateProject = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canInviteUsersToPrivateProject,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canDeleteProject = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canDeleteProject,
    currentUser,
    selectedProject,
  });

  const canEditProjectSettings = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canEditProjectSettings,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canEditProjectMarkups = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canEditProjectMarkups,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canArchiveProjects = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canArchiveProjects,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canUnarchiveProjects = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canUnarchiveProjects,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canViewDataManagement = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canViewDataManagement,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canViewAllProjectMembers = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canViewAllProjectMembers,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canViewProjectSettings = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canViewProjectSettings,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canViewProjectMarkups = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canViewProjectMarkups,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canViewProjectMarkupsAllowViewers = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canViewProjectMarkupsAllowViewers,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canEditProjectDetails = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canEditProjectDetails,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canChangeProjectAccessLevel = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canChangeProjectAccessLevel,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canViewProjectIntegrations = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canViewProjectIntegrations,
    currentUser,
    selectedProject,
    projectContext,
  });

  const canViewPhotogrammetryFeature = hasUserValidRoleProjectLevel({
    roleName: RequiredRoleProjectLevelName.canViewPhotogrammetryFeature,
    currentUser,
    selectedProject,
    projectContext,
  });

  // Set to false as they are not used in the Dashboard yet. The correct code should be implement when needed
  const canViewProjectCloudActivity = false;
  const canDeleteSnapshots = false;
  const canViewProjectsStats = false;
  const canViewProjectData = false;

  function hasUserPermissionProjectLevel({
    roleName,
    selectedProject = null,
  }: Props): boolean {
    return hasUserValidRoleProjectLevel({
      roleName,
      currentUser,
      selectedProject,
    });
  }

  return {
    canDeleteMemberFromProject,
    canView2dImagesFeature,
    canViewVideoModeFeature,
    canViewFaceBlurringFeature,
    canInviteUsersToPrivateProject,
    canDeleteProject,
    canEditProjectSettings,
    canEditProjectMarkups,
    canArchiveProjects,
    canUnarchiveProjects,
    canEditProjectDetails,
    canChangeProjectAccessLevel,
    canViewProjectCloudActivity,
    canViewAllProjectMembers,
    canViewProjectSettings,
    canDeleteSnapshots,
    canViewProjectMarkups,
    canViewProjectsStats,
    canViewProjectData,
    canViewProjectMarkupsAllowViewers,
    hasUserPermissionProjectLevel,
    canViewDataManagement,
    canViewProjectIntegrations,
    canViewPhotogrammetryFeature,
  };
}
