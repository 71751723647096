import { SdbProject } from "@custom-types/project-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import {
  UploadErrorToastType,
  useUploadErrorToast,
} from "@hooks/data-management/use-upload-error-toast";
import { useCallback, useMemo } from "react";
import { APITypes } from "@stellar/api-logic";
import { useFileUpload } from "@hooks/use-file-upload";
import {
  FileUploadTaskContext,
  MultiUploadedFileResponse,
  UploadElementType,
  UploadMultipleFilesParams,
} from "@custom-types/file-upload-types";
import { FILE_SIZE_MULTIPLIER } from "@utils/file-utils";
import { useOnSendMutation } from "@pages/project-details/project-data-management/photogrammetry-data/photogrammetry-data-mutation";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import {
  filesInfoForTracking,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { isValidFile } from "@hooks/file-upload-utils";

/**
 * The result of the upload
 */
interface UploadResult {
  /** The uploaded response */
  uploadedResponse: MultiUploadedFileResponse;
  /** The context of the file upload task */
  context: FileUploadTaskContext;
}

/**
 * The return function
 */
type ReturnFunction = (
  /** The selected files */
  selectedFiles:
    | FileList
    | File[],
  /** The uploaded ids map */
  uploadedIdsMap: { [key: APITypes.UUID]: boolean }
) => Promise<boolean>;

/**
 * Set the files for upload, replacing any previous selected ones. We allow to upload a single ELS folder at once.
 * Returns true on success, false if some kind of error happened. In that case, an error toast is displayed
 * automatically.
 * @param project - The project to upload the files to
 * @returns The return function
 */
export function useOnSelectPhotogrammetryFiles(
  project: SdbProject
): ReturnFunction {
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();
  const uploadErrorToast = useUploadErrorToast();

  const { uploadMultipleFiles, validateAndAddFailedTask } = useFileUpload();

  const SendMutation = useOnSendMutation(project);
  const allowedExtensions = useMemo(() => ["jpg", "jpeg"], []);
  // for video needs to be adjusted and upload with chunks
  const MAX_FILE_SIZE_IN_MB = 20;
  const MAX_FILE_SIZE_IN_BYTE = MAX_FILE_SIZE_IN_MB * FILE_SIZE_MULTIPLIER;

  return useCallback(
    async (
      selectedFiles: FileList | File[],
      uploadedIdsMap: { [key: APITypes.UUID]: boolean }
    ): Promise<boolean> => {
      /** Validate files before uploading */
      const context: FileUploadTaskContext = {
        uploadElementType: UploadElementType.project,
        projectId: project.id,
      };
      const allowedFiles: File[] = [];
      for (const file of selectedFiles) {
        const result = isValidFile({
          file,
          allowedExtensions,
          maxFileSize: MAX_FILE_SIZE_IN_MB,
        });
        if (result.isValid) {
          allowedFiles.push(file);
        }
      }

      const validFiles = allowedFiles.filter((file) =>
        validateAndAddFailedTask({
          file,
          allowedExtensions: allowedExtensions,
          maxFileSize: MAX_FILE_SIZE_IN_BYTE,
          context,
        })
      );
      trackEvent({
        name: DataManagementEvents.selectFiles,
        props: filesInfoForTracking(validFiles ? [...validFiles] : []),
      });

      /** if no files were selected */
      if (validFiles.length === 0) {
        uploadErrorToast(UploadErrorToastType.noFiles);
        return false;
      }

      async function uploadFiles(): Promise<UploadResult> {
        return new Promise<UploadResult>((resolve, reject) => {
          const uploadParams: UploadMultipleFilesParams = {
            files: validFiles,
            onUploadStart: () => undefined,
            onUploadProgress: () => undefined,
            onUploadComplete: (uploadedResponse, context) => {
              // Resolve the promise with both uploadedResponse and context
              resolve({ uploadedResponse, context });
            },
            context,
          };

          // Call the upload function
          uploadMultipleFiles(uploadParams).catch((error) => {
            // Reject the promise in case of an error
            reject(error);
          });
        });
      }

      try {
        trackEvent({
          name: DataManagementEvents.startUpload,
          props: filesInfoForTracking(validFiles),
        });

        /** Upload files */
        const result = await uploadFiles();
        const successfulUploads = result.uploadedResponse.successful;
        const failedUploads = result.uploadedResponse.failed;
        const canceledUploads = result.uploadedResponse.canceled;

        trackEvent({
          name: DataManagementEvents.finishUpload,
          props: {
            successfulUploads: successfulUploads.length,
            failedUploads: failedUploads.length,
            canceledUploads: canceledUploads.length,
          },
        });

        /** Send the mutation */
        await SendMutation({
          selectedFiles: validFiles,
          selectedSheet: null,
          uploadedResponse: result.uploadedResponse,
          context: result.context,
        });

        // trigger the photogrammetry service
        return true;
      } catch (error) {
        handleErrorWithToast({
          id: `uploadFileWithChunks-${Date.now().toString()}`,
          title: "Error uploading file",
          error,
        });
        return false;
      }
    },
    [
      project.id,
      validateAndAddFailedTask,
      allowedExtensions,
      MAX_FILE_SIZE_IN_BYTE,
      uploadErrorToast,
      trackEvent,
      SendMutation,
      uploadMultipleFiles,
      handleErrorWithToast,
    ]
  );
}
