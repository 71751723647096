import { useCallback } from "react";
import { SdbProject } from "@custom-types/project-types";
import { APITypes } from "@stellar/api-logic";
import { useDataManagementInputType, DataManagementInputType } from "@pages/project-details/project-data-management/data-management-input-type";
import { useOnSelectBlinkFiles } from "@hooks/data-management/use-on-select-files-blink";
import { useOnSelectPhotogrammetryFiles } from "@hooks/data-management/use-on-select-files-photogrammetry";
import {
  useUploadErrorToast,
  UploadErrorToastType,
} from "@hooks/data-management/use-upload-error-toast";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { useAppSelector } from "@store/store-helper";

export type ReturnFunction = (
  selectedFiles: FileList | File[],
  uploadedIdsMap: { [key: APITypes.UUID]: boolean },
) => Promise<boolean>;

/**
 * Combined hook for handling file selection and upload for both Blink and Photogrammetry files.
 * Returns true on success, false if some kind of error happened.
 */
export function useOnSelectFiles(project: SdbProject): ReturnFunction {
  const determineInputType = useDataManagementInputType();
  const onSelectBlinkFiles = useOnSelectBlinkFiles(project);
  const onSelectPhotogrammetryFiles = useOnSelectPhotogrammetryFiles(project);
  const uploadErrorToast = useUploadErrorToast();
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const { canViewPhotogrammetryFeature } = useHasUserValidRoleProjectLevel({
    selectedProject: project,
  });

  return useCallback(
    async (
      selectedFiles: FileList | File[],
      uploadedIdsMap: { [key: APITypes.UUID]: boolean }
    ): Promise<boolean> => {
      const inputType = determineInputType(selectedFiles);

      switch (inputType) {
        case DataManagementInputType.GLS:
          return onSelectBlinkFiles(selectedFiles, uploadedIdsMap);

        case DataManagementInputType.PHOTOGRAMMETRY:
          if (!canViewPhotogrammetryFeature && !isDevModeEnabled) {
            uploadErrorToast(UploadErrorToastType.noPermission);
            return false;
          }
          return onSelectPhotogrammetryFiles(selectedFiles, uploadedIdsMap);

        case DataManagementInputType.UNKNOWN:
        default:
          uploadErrorToast(UploadErrorToastType.invalidFormat);
          return false;
      }
    },
    [
      canViewPhotogrammetryFeature,
      determineInputType,
      onSelectBlinkFiles,
      onSelectPhotogrammetryFiles,
      uploadErrorToast,
      isDevModeEnabled,
    ]
  );
}
