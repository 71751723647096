import { useCallback } from "react";
import { useToast } from "@hooks/use-toast";
import { MAX_FILE_NAME_LENGTH } from "@hooks/upload-tasks/upload-tasks-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";

export enum UploadErrorToastType {
  tooLongFilename = "tooLongFilename",
  noFiles = "noFiles",
  invalidFormat = "invalidFormat",
  noNewFiles = "noNewFiles",
  noPermission = "noPermission",
}

type ReturnFunction = (
  type: UploadErrorToastType,
) => void;

/** Provides various error toasts for Blink scan upload errors. */
export function useUploadErrorToast(): ReturnFunction {
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();

  return useCallback((type: UploadErrorToastType): void => {
      let message: string;
      switch (type) {
        case UploadErrorToastType.tooLongFilename:
          message = "Folder or file name is too long. " +
            `Please change it to be shorter than ${MAX_FILE_NAME_LENGTH + 1} characters.`;
          break;
        case UploadErrorToastType.noFiles:
          message = "Please upload the folder with raw Blink data (.gls) which contains a file called \"index-v2\".";
          break;
        case UploadErrorToastType.invalidFormat:
          message = "Some of the selected scans have an invalid format.";
          break;
        case UploadErrorToastType.noNewFiles:
          message = "All selected scans have already been uploaded.";
          break;
        case UploadErrorToastType.noPermission:
          message = "You don't have permission to upload data.";
          break;
        default:
          message = "Failed to read selected files. Please try again.";
          break;
      }

      showToast({
        message,
        type: "error",
      });

      trackEvent({
        name: DataManagementEvents.selectFilesError,
        props: {
          error: type,
          message,
        },
      });
    },
    [showToast, trackEvent]
  );
}
