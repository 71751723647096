import { useCallback } from "react";
import {
  FileUploadTaskContext,
  MultiUploadedFileResponse,
} from "@custom-types/file-upload-types";
import {
  CreateMutationAddPhotogrammetryInput,
  MutationAddPhotogrammetryInput,
  UploadedFileInfo,
} from "@faro-lotv/service-wires";
import {
  IElement,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import { SdbProject } from "@custom-types/project-types";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { usePhotogrammetryApiClient } from "@api/photogrammetry-api/use-photogrammetry-api-client";
import { useGetImageDimensions } from "@pages/project-details/project-data-management/photogrammetry-data/photogrammetry-data-utils";

/**
 * Parameters for the mutation
 * @param selectedFiles - The files selected by the user
 * @param selectedSheet - The selected sheet
 * @param uploadedResponse - The response from the upload
 * @param context - The context of the file upload task
 *
 * @returns Promise<void> - The result of the mutation
 */
export type MutationParams = {
  selectedFiles: FileList | File[];
  selectedSheet: IElement | null;
  uploadedResponse: MultiUploadedFileResponse;
  context: FileUploadTaskContext;
};

/**
 * The return function
 * @param params - The parameters for the mutation
 *
 * @returns Promise<void> - The result of the mutation
 */
type ReturnFunction = (params: MutationParams) => Promise<void>;

/**
 * The hook to send the mutation
 * @param project - The project to send the mutation to
 * @returns ReturnFunction - The return function to send the mutation
 */
export function useOnSendMutation(project: SdbProject): ReturnFunction {
  const projectAPI = useProjectApiClient({ projectId: project.id });
  const currentUser = useAppSelector(currentUserSelector);
  const photogrammetryApiClient = usePhotogrammetryApiClient({
    projectId: project.id,
  });
  const getImageDimensions = useGetImageDimensions();

  return useCallback(
    async ({
      selectedFiles,
      selectedSheet,
      uploadedResponse,
      context,
    }: MutationParams): Promise<void> => {
      if (!currentUser?.id) {
        throw new Error("Missing required userId for photogrammetry task");
      }

      const uploadedFileEntries = await Promise.all(
        uploadedResponse.successful.map(
          async (uploaded): Promise<UploadedFileInfo> => {
            const date = new Date();
            const localFile = Array.from(selectedFiles).find(
              (f) => f.name === uploaded.fileName
            );

            let dimensions = { width: 0, height: 0 };
            if (localFile) {
              dimensions = await getImageDimensions(localFile);
            }

            return {
              uri: uploaded.downloadUrl,
              name: uploaded.fileName,
              pixelWidth: dimensions.width,
              pixelHeight: dimensions.height,
              duration: null,
              FileName: uploaded.fileName,
              fileSize: uploaded.fileSize,
              md5: uploaded.md5,
              createdAt: date.toUTCString(),
            };
          }
        )
      );

      const root = (
        await projectAPI.getIElements({ types: [IElementType.projectRoot] })
      ).page[0];

      if (selectedSheet === null) {
        const areaSections = await projectAPI.getIElements({
          types: [IElementType.section],
          typeHints: [IElementTypeHint.area],
        });
        if (areaSections.page[0] !== undefined) {
          selectedSheet = areaSections.page[0];
        }
      }
      let timeSeriesId = null;
      if (selectedSheet != null) {
        const timeSeriesQuery = await projectAPI.getIElements({
          types: [IElementType.timeSeries],
          parentIds: [selectedSheet.id],
        });

        if (timeSeriesQuery.page.length !== 0) {
          timeSeriesId = timeSeriesQuery.page[0].id;
        }
      }

      const mutation: MutationAddPhotogrammetryInput =
        CreateMutationAddPhotogrammetryInput({
          images2d: uploadedFileEntries,
          images360: [],
          videos2d: [],
          videos360: [],
          rootId: root.id,
          targetSheetId: selectedSheet?.id ?? null,
          timeseriesId: timeSeriesId,
        });

      await projectAPI.applyMutations([mutation]);

      if (!mutation.dataset?.id) {
        throw new Error("Missing datasetId for photogrammetry task");
      }

      const payload = {
        projectId: project.id,
        userId: currentUser?.id,
        datasetId: mutation.dataset?.id,
      };

      await photogrammetryApiClient.triggerPhotogrammetryTask({
        projectId: payload.projectId,
        userId: payload.userId,
        datasetId: payload.datasetId,
      });
    },
    [
      currentUser?.id,
      projectAPI,
      project.id,
      photogrammetryApiClient,
      getImageDimensions,
    ]
  );
}
