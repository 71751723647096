import { useCallback } from "react";
import { isGLS } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { getLsDataV2Package } from "@src/api/stagingarea-api/stagingarea-api";

/**
 * The type of data management input.
 */
export enum DataManagementInputType {
  /** The data is GLS. */
  GLS = "GLS",
  /** The data is photogrammetry. */
  PHOTOGRAMMETRY = "PHOTOGRAMMETRY",
  /** The data is unknown. */
  UNKNOWN = "UNKNOWN",
}

/** The allowed image extensions. */
const ALLOWED_IMAGE_EXTENSIONS = [".jpg", ".jpeg"];

/**
 * Whether the file is an image.
 * @param fileName - The name of the file.
 * @returns Whether the file is an image.
 */
function isImageFile(fileName: string): boolean {
  const extension = fileName.toLowerCase().slice(fileName.lastIndexOf("."));
  return ALLOWED_IMAGE_EXTENSIONS.includes(extension);
}

/**
 * Use the data management input type.
 * @returns The data management input type.
 */
export function useDataManagementInputType(): (selectedFiles: FileList | File[]) => DataManagementInputType {
  return useCallback(
    (selectedFiles: FileList | File[]): DataManagementInputType => {
      if (!selectedFiles || selectedFiles.length === 0) {
        return DataManagementInputType.UNKNOWN;
      }
      /** Filter out hidden files e.g. .DS_Store*/
      const files = Array.from(selectedFiles).filter(
        (file) => !file.name.startsWith(".")
      );

      // Check if files are GLS
      const glsFiles = files.filter((file) => isGLS(file.name));
      if (glsFiles.length > 0) {
        const lsDataV2Files = getLsDataV2Package(files);
        if (lsDataV2Files?.isValid) {
          return DataManagementInputType.GLS;
        }
      }

      // Check if files are images for photogrammetry
      const areAllImages = files.every((file) => isImageFile(file.name));
      if (areAllImages) {
        return DataManagementInputType.PHOTOGRAMMETRY;
      }

      return DataManagementInputType.UNKNOWN;
    },
    []
  );
}
